<template>
  <div class="body">
    <!-- 门诊缴费记录 -->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <!-- 记录列表 -->
    <div class="content-list" v-for="(item, index) in paylist" :key="index">
      <div class="baseinfo-item">
        <span class="baseinfo-item-label">执行科室：</span>{{ item.dept_name }}
      </div>
      <div class="baseinfo-item">
        <span class="baseinfo-item-label">就诊时间：</span>{{ item.time }}
      </div>
      <div class="baseinfo-item">
        <span class="baseinfo-item-label">费用：</span
        ><span style="color: #ee0a24">￥{{ item.amount }}</span>
      </div>
      <div class="van-hairline--bottom"></div>
      <div class="order-btn">
        <van-button plain size="small" type="primary" @click="costsDetail(item)"
          >费用详情</van-button
        >
        <van-button plain size="small" type="primary">门诊病历</van-button>
        <van-button plain size="small" type="primary">电子票据</van-button>
        <van-button plain size="small" type="danger">退款</van-button>
      </div>
    </div>
    <div class="baogao3" v-if="paylist.length == 0">
      <img src="../../assets/img/ucenter/21.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OutpatientPaymentRecord",
  data() {
    return {
      showPopup: false, // 弹出层
      list: [],
      personId: "",
      chooseItem: "",
      isLoading2: false,
      loading2: false,
      finished2: false,
      paylist: [],
    };
  },
  methods: {
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    onRefresh() {
      this.isLoading2 = true;
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      postData.status = true;
      this.$http.get("/api/search/outp", postData).then((result) => {
        this.loading2 = false;
        this.paylist = result.data;
        this.isLoading2 = false;
        if (this.paylist.length >= result.data.total) {
          this.finished2 = true;
        }
      });
    },
    onLoad2() {
      if (this.paylist.length == 0) {
        this.finished2 = true;
        return;
      }
      this.getDataList();
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getDataList();
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.getDataList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDataList() {
      this.loading2 = true;
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      postData.status = true;
      this.$http.get("/api/search/outp", postData).then((result) => {
        this.loading2 = false;
        this.paylist = result.data;
        // this.paylist = [ { "id": 1, "time": "2021-10-20 00:00:00", "deptName": "检验科", "amount": 0.01, } ];
        this.isLoading2 = false;
        if (this.paylist.length >= result.data.total) {
          this.finished2 = true;
        }
      });
    },
    // 查看费用详情
    costsDetail(item) {
      item.name = this.chooseItem.name;
      sessionStorage.setItem("recordDetail", JSON.stringify(item));
      this.$router.push({
        path: "/PaymentRecordDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  left: -2px;
}
.content-list {
  width: 9rem;
  margin: 10px auto;
  text-align: left;
  padding: 12px;
  padding-bottom: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.baseinfo-item {
  font-size: 0.4rem;
  line-height: 26px;
}
.baseinfo-item-label {
  width: 80px;
  display: inline-block;
  text-align: right;
}

.order-btn {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.order-btn .van-button {
  border-radius: 5px;
}
/deep/ .van-submit-bar__bar {
  padding: 0;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
</style>
